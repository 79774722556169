import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getEvent } from '../store/event';

const SOCIALS = {
    email: {
        attributeName: 'email',
        iconName: 'envelope',
        title: 'Contacter par email',
    },
    facebook: {
        attributeName: 'facebook_account',
        iconName: 'facebook',
        title: 'Voir sur Facebook',
    },
    linkedin: {
        attributeName: 'linkedin_account',
        iconName: 'linkedin',
        title: 'Voir sur LinkedIn',
    },
    twitter: {
        attributeName: 'twitter_account',
        iconName: 'twitter',
        title: 'Voir sur Twitter',
    },
};

class SocialsButtons extends PureComponent {

    static propTypes = {
        data: PropTypes.shape({
            orga: PropTypes.object.isRequired,
        }).isRequired,
    }

    render() {
        const { data } = this.props;
        const buttons = [];
        Object.values(SOCIALS).forEach((socialData) => {
            if (!data.orga[socialData]) return;
            buttons.push(
                <a
                    className="c-btn c-btn--tertiary c-btn--square br50"
                    title={socialData.title}
                    href={data.orga[socialData]}
                >
                    <i className={`fa fa-${socialData.iconName}`} />
                </a>,
            );
        });
        return (
            <>
                {buttons}
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    data: getEvent(state),
});

export default connect(
    mapStateToProps,
)(SocialsButtons);
