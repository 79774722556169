export default {
    login: {
        build: (eventId) => `/events/${eventId}`,
        test: (pathname) => /^\/events\/(\d+)$/.test(pathname),
        raw: '/events/:eventId',
    },
    scenes: {
        build: (eventId) => `/events/${eventId}/scenes`,
        test: (pathname) => /^\/events\/(\d+)\/scenes$/.test(pathname),
        raw: '/events/:eventId/scenes',
    },
    activities: {
        build: (eventId) => `/events/${eventId}/activities`,
        test: (pathname) => /^\/events\/(\d+)\/activities$/.test(pathname),
        raw: '/events/:eventId/activities',
    },
    community: {
        build: (eventId) => `/events/${eventId}/community`,
        test: (pathname) => /^\/events\/(\d+)\/community$/.test(pathname),
        raw: '/events/:eventId/community',
    },
};
