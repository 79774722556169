import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getEvent } from '../store/event';

const eventDateOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
};

class EventDetailsPreview extends PureComponent {

    static propTypes = {
        data: PropTypes.shape({
            name: PropTypes.string.isRequired,
            begin: PropTypes.number.isRequired,
            orga: PropTypes.shape({
                name: PropTypes.string.isRequired,
                pictures: PropTypes.shape({
                    original: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
    }

    render() {
        const { data } = this.props;
        return (
            <div className="u-font-size--3 u-color__grey--darker u-font-weight--medium">
                Par <span className="u-font-weight--semi-bold">{data.orga.name}</span>
                <br />
                {new Intl.DateTimeFormat('fr-FR', eventDateOptions).format(new Date(data.begin * 1000))}
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    data: getEvent(state),
});

export default connect(
    mapStateToProps,
)(EventDetailsPreview);
