import {
    all, call, put, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import YurplanAPI from '../../YurplanAPI';
import { setApiAccessToken } from '../apiTokens';

import {
    getEventId,
    setEvent,
} from './index';

function* getEventData() {
    const eventId = yield select(getEventId);
    const results = yield YurplanAPI.getEventData(eventId);
    if (!results.results) {
        yield all([
            call(YurplanAPI.resetTokens),
            put(setApiAccessToken(null)),
            put(push('/404')),
        ]);
        return;
    }
    yield put(setEvent({
        ...results.results,
    }));
}

function* rootSaga() {
    yield all([
        getEventData(),
    ]);
}

export default rootSaga;
