export const reducerKey = 'ApiTokens';

export const initialState = {
    apiAccessToken: null,
    tokenInitiated: false,
};

const ACTION_PREFIX = 'API_TOKENS';

export const LOAD_TOKEN_SUCCESS = `${ACTION_PREFIX}/LOAD_TOKEN_SUCCESS`;
export const LOAD_TOKEN_FAILURE = `${ACTION_PREFIX}/LOAD_TOKEN_FAILURE`;
export const SET_API_ACCESS_TOKEN = `${ACTION_PREFIX}/SET_API_ACCESS_TOKEN`;
export const LOGOUT = `${ACTION_PREFIX}/LOGOUT`;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_API_ACCESS_TOKEN:
            return {
                ...state,
                apiAccessToken: action.payload.apiAccessToken,
            };
        case LOAD_TOKEN_SUCCESS:
        case LOAD_TOKEN_FAILURE:
            return {
                ...state,
                tokenInitiated: true,
            };
        case LOGOUT:
            return {
                ...state,
            };
        default:
            return state;
    }
}

export const loadTokenSuccess = () => ({ type: LOAD_TOKEN_SUCCESS });
export const loadTokenFailure = () => ({ type: LOAD_TOKEN_FAILURE });
export const logout = () => ({ type: LOGOUT });

export const setApiAccessToken = (apiAccessToken) => ({
    type: SET_API_ACCESS_TOKEN,
    payload: { apiAccessToken },
});

export const getApiAccessToken = (state) => state[reducerKey].apiAccessToken;
export const isTokenInitiated = (state) => state[reducerKey].tokenInitiated;
