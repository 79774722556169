import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import APP_CONFIG from '../YurplanAPI/config';

import {
    getEmailAddress,
    getToken,
    setEmailAddress,
    setToken,
    submit,
    getError,
    isLoading as isLoginLoading,
    ERROR_MESSAGES,
} from '../store/userLogin';
import { getEvent } from '../store/event';

import HelpMessage from './HelpMessage';

const UserLoginForm = ({
    emailAddress, ticketToken, isLoading, error,
    data,
    UserLoginActions,
}) => (
    <main className="u-col--l-push-1 u-col--l-4 u-col--m-6 u-col-12 py-3">
        <hr className="s-hr u-display__none--l u-display__none--xl" />
        <div className="u-font-size--6 u-font-weight--semi-bold u-color__grey--darker mb-3">
            Accéder à l'espace participant
        </div>
        <form onSubmit={UserLoginActions.submit}>
            <div className={`c-form__line ${error ? 'c-form__line_error' : ''}`}>
                <label
                    className="c-form__label required"
                    htmlFor="email"
                >
                    Email
                </label>
                <div className="c-form__field">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={emailAddress}
                        onChange={UserLoginActions.setEmailAddress}
                        className="c-form__input"
                        placeholder="exemple@mail.fr"
                    />
                </div>
                <div className="c-form__info">
                    L’email avec lequel vous avez effectué votre réservation.
                </div>
            </div>
            <div className={`c-form__line ${error ? 'c-form__line_error' : ''}`}>
                <label
                    className="c-form__label required"
                    htmlFor="ticket-token"
                >
                    Numéro de {data.event_term === 1 ? 'réservation' : 'billet'}
                </label>
                <div className="c-form__field">
                    <input
                        type="text"
                        id="ticket-token"
                        name="ticket-token"
                        value={ticketToken}
                        onChange={UserLoginActions.setToken}
                        className="c-form__input"
                        placeholder="69XXXXXXXXX"
                    />
                </div>
                <div className="c-form__info">
                    Le numéro de {data.event_term === 1 ? 'réservation' : 'billet'} est à usage unique et se trouve
                    sous votre nom et prénom sur votre {data.event_term === 1 ? 'titre d\'accès' : 'billet'}.
                </div>
                {
                    error ? (
                        <div className="c-form__info c-form__info_error">
                            {ERROR_MESSAGES[error]}
                        </div>
                    ) : null
                }
            </div>
            <button
                type="submit"
                className={`c-btn c-btn--secondary c-btn--fluid--s mt-3 ${isLoading ? 'c-btn--disabled' : ''}`}
                disabled={isLoading}
            >
                Rejoindre l'espace participant →
            </button>
        </form>
        <div className="my-4">
            <HelpMessage />
        </div>
        <a
            className="c-btn c-btn--tertiary c-btn--fluid--s mb-4"
            href={`${APP_CONFIG.endpoint}/event/${data.slug}/${data.id}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {data.event_term === 1 ? 'Prendre une réservation' : 'Acheter un billet'} →
        </a>
    </main>
);

UserLoginForm.propTypes = {
    emailAddress: PropTypes.string.isRequired,
    ticketToken: PropTypes.string.isRequired,
    error: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    UserLoginActions: PropTypes.shape({
        setEmailAddress: PropTypes.func.isRequired,
        setToken: PropTypes.func.isRequired,
        submit: PropTypes.func.isRequired,
    }).isRequired,
    data: PropTypes.shape({
        id: PropTypes.number,
        slug: PropTypes.string,
        event_term: PropTypes.number,
    }).isRequired,
};

UserLoginForm.defaultProps = {
    error: null,
};

const mapStateToProps = (state) => ({
    emailAddress: getEmailAddress(state),
    ticketToken: getToken(state),
    data: getEvent(state),
    error: getError(state),
    isLoading: isLoginLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    UserLoginActions: bindActionCreators({
        setEmailAddress: (e) => setEmailAddress(e.target.value),
        setToken: (e) => setToken(e.target.value),
        submit: (e) => {
            e.preventDefault();
            return submit();
        },
    }, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserLoginForm);
