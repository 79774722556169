import {
    all,
    cancel,
    delay,
    fork,
    put,
    take,
    takeLatest,
    select,
    call,
} from 'redux-saga/effects';
import { getLocation, push, replace } from 'connected-react-router';

import {
    LOAD_TOKEN_FAILURE,
    LOAD_TOKEN_SUCCESS,
    LOGOUT,
    setApiAccessToken,
    loadTokenFailure,
    loadTokenSuccess,
    logout,
    getApiAccessToken,
} from './index';

import YurplanAPI from '../../YurplanAPI';
import {
    getEventId,
    eventHasActivities as eventHasActivitiesSelector,
    eventHasCommunity as eventHasCommunitySelector,
    eventHasStages as eventHasStagesSelector,
} from '../event';
import {
    getToken,
    setEmailAddress,
    setError,
    setTicket,
    setToken,
} from '../userLogin';
import Routes from '../../Routes';

const DELAY = 50 * 1000; // Static delay
const MAX_RANDOM_DELAY = 5 * 1000; // Max random delay

export function* redirectToAppropriatePage() {
    const [
        eventId,
        { pathname },
        eventHasActivities,
        eventHasStages,
        eventHasCommunity,
    ] = yield all([
        select(getEventId),
        select(getLocation),
        select(eventHasActivitiesSelector),
        select(eventHasStagesSelector),
        select(eventHasCommunitySelector),
    ]);

    if (eventHasStages && !Routes.scenes.test(pathname)) {
        yield put(replace(Routes.scenes.build(eventId)));
    } else if (eventHasActivities && !Routes.activities.test(pathname)) {
        yield put(replace(Routes.activities.build(eventId), {}));
    } else if (eventHasCommunity && !Routes.community.test(pathname)) {
        yield put(replace(Routes.community.build(eventId)));
    }
}

export function* shouldRedirect() {
    const [
        { pathname },
        eventHasActivities,
        eventHasStages,
        eventHasCommunity,
        apiAccessToken,
        token,
    ] = yield all([
        select(getLocation),
        select(eventHasActivitiesSelector),
        select(eventHasStagesSelector),
        select(eventHasCommunitySelector),
        select(getApiAccessToken),
        select(getToken),
    ]);
    if (Routes.login.test(pathname)) {
        return apiAccessToken && token;
    }
    if (Routes.scenes.test(pathname)) {
        return !eventHasStages;
    }
    if (Routes.activities.test(pathname)) {
        return !eventHasActivities;
    }
    if (Routes.community.test(pathname)) {
        return !eventHasCommunity;
    }
    return false;
}

function* onLoadToken() {
    //  Load (API and ticket) tokens from cookie
    const accessToken = YurplanAPI.getTokenFromCookie();
    const ticketToken = YurplanAPI.getTicketTokenFromCookie();
    const emailAddress = YurplanAPI.getEmailAddressFromCookie();
    // No token found, logout
    if (!accessToken || !ticketToken) {
        yield put(loadTokenFailure());
        return;
    }
    // Set tokens into the store
    yield all([
        put(setApiAccessToken(accessToken)),
        put(setToken(ticketToken)),
        put(setEmailAddress(emailAddress)),
    ]);
    // Set tokens to the API
    YurplanAPI.setTokens(accessToken, ticketToken, emailAddress);
    const ticketData = yield YurplanAPI.getTicket(ticketToken);
    yield put(setTicket(ticketData));
    if (yield shouldRedirect()) {
        yield redirectToAppropriatePage();
    }
    yield put(loadTokenSuccess());
}

function* onLogout() {
    const [
        eventId,
    ] = yield all([
        select(getEventId),
    ]);
    const apiAccessToken = YurplanAPI.getTokenFromCookie();
    const ticketToken = YurplanAPI.getTicketTokenFromCookie();
    try {
        const logoutActions = [];
        if (ticketToken) {
            logoutActions.push(call(YurplanAPI.logoutLive, ticketToken));
        }
        if (apiAccessToken) {
            logoutActions.push(call(YurplanAPI.logout));
        }
        yield all(logoutActions);
    } catch (e) {
        // Ignore logout API errors
    }
    yield all([
        call(YurplanAPI.resetTokens),
        put(setTicket(null)),
        put(setApiAccessToken(null)),
        put(push(Routes.login.build(eventId))),
    ]);
}

function* heartBeat() {
    const ticketToken = yield select(getToken);
    while (true) {
        try {
            const heartbeatResponse = yield YurplanAPI.heartbeat(ticketToken);
            // The token is in use on another browser, force logout and display an error message
            if (heartbeatResponse.status === 401) {
                yield all([
                    put(setError('TICKET_CONFLICT')),
                    put(logout()),
                ]);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error during heartbeat process', e);
        }
        yield delay(DELAY + Math.floor(Math.random() * MAX_RANDOM_DELAY));
    }
}

function* onLoadTokenSuccess() {
    while (yield take(LOAD_TOKEN_SUCCESS)) {
        const heartbeatTask = yield fork(heartBeat); // Start the task
        yield take(LOGOUT); // Wait until logout
        yield cancel(heartbeatTask); // Cancel the heartbeat task
    }
}

function* rootSaga() {
    yield all([
        onLoadToken(),
        onLoadTokenSuccess(),
        takeLatest([
            LOAD_TOKEN_FAILURE,
            LOGOUT,
        ], onLogout),
    ]);
}

export default rootSaga;
