import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getEvent } from '../store/event';

import SocialButtons from './SocialButtons';
import EventDetailsPreview from './EventDetailsPreview';

import defaultBackgroundImage from '../assets/default_background_event.jpg';

class EventPreview extends PureComponent {

    static propTypes = {
        data: PropTypes.shape({
            name: PropTypes.string.isRequired,
            begin: PropTypes.number.isRequired,
            orga: PropTypes.shape({
                name: PropTypes.string.isRequired,
                pictures: PropTypes.shape({
                    original: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
            pictures: PropTypes.shape({
                cover: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }

    componentDidUpdate(prevProps) {
        const { data: newData } = this.props;
        const { data: oldData } = prevProps;
        if (newData.name !== oldData.name) {
            document.title = newData.name;
        }
    }

    render() {
        const { data } = this.props;
        return (
            <>
                <header className="u-col--l-push-1 u-col--l-5 u-col--m-6 u-col-12">
                    <div
                        className="c-card-event__img"
                        style={{
                            backgroundImage: `url(${data.pictures.cover ?? defaultBackgroundImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            paddingTop: '33%',
                        }}
                    />

                    <div className="c-card-event__name mt-4">
                        {data.name}
                    </div>
                    <div className="c-grid__row c-grid__row--no-col">
                        <img
                            className="br50 mr-3"
                            src={data.orga.pictures.original}
                            alt="event organisation logo"
                            style={{ width: '45px', height: '45px' }}
                        />
                        <EventDetailsPreview />
                    </div>
                    <div className="c-grid__row c-grid__row--no-col mt-4">
                        <SocialButtons />
                    </div>
                </header>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    data: getEvent(state),
});

export default connect(
    mapStateToProps,
)(EventPreview);
