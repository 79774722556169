import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocation } from 'connected-react-router';

import {
    getEvent,
    getEventId,
    eventHasActivities as eventHasActivitiesSelector,
    eventHasCommunity as eventHasCommunitySelector,
    eventHasStages as eventHasStagesSelector,
} from '../store/event';

import Routes from '../Routes';

class TopBar extends PureComponent {

    static propTypes = {
        eventId: PropTypes.number.isRequired,
        eventData: PropTypes.shape({
            name: PropTypes.string.isRequired,
            orga: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        eventHasActivities: PropTypes.bool.isRequired,
        eventHasCommunity: PropTypes.bool.isRequired,
        eventHasStages: PropTypes.bool.isRequired,
    }

    getTabClassName(route) {
        const { location } = this.props;
        const classNames = ['c-tab-menu__item'];
        if (route.test(location.pathname)) {
            classNames.push('is-active');
        }
        return classNames.join(' ');
    }

    render() {
        const {
            eventData,
            eventId,
            eventHasActivities,
            eventHasCommunity,
            eventHasStages,
        } = this.props;
        return (
            <>
                <div className="c-grid c-grid--l mb-4">
                    <div className="c-grid__row c-grid__row--space-between c-grid__row--no-col">
                        <div className="u-font-weight--semi-bold u-font-size--5 u-color__grey--darker">
                            {eventData.name}
                            <div className="u-font-weight--medium u-font-size--3 mt-1">
                                Par <span className="u-font-weight--semi-bold">{eventData.orga.name}</span>
                            </div>
                        </div>
                    </div>
                    <nav className="mt-3">
                        {
                            eventHasStages ? (
                                <div className={this.getTabClassName(Routes.scenes)}>
                                    <a href={`#${Routes.scenes.build(eventId)}`}>Scènes</a>
                                </div>
                            ) : null
                        }
                        {
                            eventHasActivities ? (
                                <div className={this.getTabClassName(Routes.activities)}>
                                    <a href={`#${Routes.activities.build(eventId)}`}>Planning</a>
                                </div>
                            ) : null
                        }
                        {
                            eventHasCommunity ? (
                                <div className={this.getTabClassName(Routes.community)}>
                                    <a href={`#${Routes.community.build(eventId)}`}>Communauté</a>
                                </div>
                            ) : null
                        }
                    </nav>
                    <hr className="s-hr mt-3" />
                </div>
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    eventData: getEvent(state),
    eventId: getEventId(state),
    location: getLocation(state),
    eventHasActivities: eventHasActivitiesSelector(state),
    eventHasCommunity: eventHasCommunitySelector(state),
    eventHasStages: eventHasStagesSelector(state),
});

export default connect(mapStateToProps)(TopBar);
