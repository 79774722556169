import { getLocation } from 'connected-react-router';

export const reducerKey = 'Event';

export const initialState = {
    event: {
        id: -1,
        name: 'EventName',
        begin: 0,
        orga: {
            name: 'Organisation',
            pictures: {
                original: '',
            },
        },
        pictures: {
            cover: '',
        },
    },
};

const ACTION_PREFIX = 'EVENT';

export const SET_EVENT = `${ACTION_PREFIX}/SET_EVENT`;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_EVENT:
            return {
                ...state,
                event: action.payload.event,
            };
        default:
            return state;
    }
}

export const setEvent = (event) => ({
    type: SET_EVENT,
    payload: {
        event,
    },
});
export const getEventId = (state) => {
    const { pathname } = getLocation(state);
    const matches = pathname.match(/^\/events\/(\d+)/);
    if (!matches) return -1;
    return Number.parseInt(matches[1], 10);
};

export const getEvent = (state) => state[reducerKey].event;
export const eventHasStages = (state) => getEvent(state).has_stages ?? false;

export const eventHasActivities = (state) => getEvent(state).has_workshops ?? false;

export const getCommunityId = (state) => getEvent(state).community_identifier;
export const eventHasCommunity = (state) => !!getCommunityId(state);
