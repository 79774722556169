import APP_CONFIG from './config';

let accessToken = null;
let ticketToken = null;
let emailAddress = null;

// Source https://www.tutorialrepublic.com/javascript-tutorial/javascript-cookies.php
function getCookie(name) {
    const cookieArr = document.cookie.split(';');

    for (let i = 0; i < cookieArr.length; i += 1) {
        const cookiePair = cookieArr[i].split('=');
        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }

    return null;
}

// Source https://dmitripavlutin.com/timeout-fetch-request/#2-timeout-a-fetch-request
async function fetchWithTimeout(resource, options) {
    const { timeout = 10000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
    });
    clearTimeout(id);

    return response;
}

function getRange(page, pageSize) {
    const begin = (page - 1) * pageSize;
    const end = (page) * pageSize;
    return `${begin}-${end}`;
}

export default {
    saveTokens: (expiration) => {
        const date = new Date();
        date.setTime(date.getTime() + (expiration * 1000));
        const expires = date.toGMTString();
        document.cookie = `accessToken=${accessToken}; expires=${expires}; domain=${APP_CONFIG.cookie_domain}; path=/; SameSite=Strict; ${process.env.NODE_ENV === 'production' ? 'Secure' : ''};`;
        document.cookie = `ticketToken=${ticketToken}; expires=${expires}; domain=${APP_CONFIG.cookie_domain}; path=/; SameSite=Strict; ${process.env.NODE_ENV === 'production' ? 'Secure' : ''};`;
        document.cookie = `emailAddress=${emailAddress}; expires=${expires}; domain=${APP_CONFIG.cookie_domain}; path=/; SameSite=Strict; ${process.env.NODE_ENV === 'production' ? 'Secure' : ''};`;
    },
    resetTokens: () => {
        document.cookie = `accessToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${APP_CONFIG.cookie_domain}; path=/; SameSite=Strict; ${process.env.NODE_ENV === 'production' ? 'Secure' : ''};`;
        document.cookie = `ticketToken=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${APP_CONFIG.cookie_domain}; path=/; SameSite=Strict; ${process.env.NODE_ENV === 'production' ? 'Secure' : ''};`;
        document.cookie = `emailAddress=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${APP_CONFIG.cookie_domain}; path=/; SameSite=Strict; ${process.env.NODE_ENV === 'production' ? 'Secure' : ''};`;
    },
    setTokens: (_accessToken, _ticketToken, _emailAddress) => {
        accessToken = _accessToken;
        ticketToken = _ticketToken;
        emailAddress = _emailAddress;
    },
    getTokenFromCookie: () => getCookie('accessToken'),
    getTicketTokenFromCookie: () => getCookie('ticketToken'),
    getEmailAddressFromCookie: () => getCookie('emailAddress'),
    hasTokens: () => !!accessToken && !!ticketToken,
    login: (email, token, eventId) => (
        fetch(`${APP_CONFIG.apiEndpoint}/v1/token`, {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                client_id: APP_CONFIG.client_id,
                client_secret: APP_CONFIG.client_secret,
                grant_type: 'stage_event_ticket_connect',
                email,
                ticket_token: token,
                event_id: eventId,
                scope: APP_CONFIG.scope,
            }),
        }).then((r) => r.json())
    ),
    getEventData: (eventId) => (
        fetch(`${APP_CONFIG.apiEndpoint}/v1/events/${eventId}`, {
            crossDomain: true,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },

        }).then((r) => r.json())
    ),
    getEventStages: (eventId, page = 1, pageSize = 30) => {
        const range = getRange(page, pageSize);
        return fetch(`${APP_CONFIG.apiEndpoint}/v1/events/${eventId}/stages?range=${range}`, {
            crossDomain: true,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((r) => r.json());
    },
    getEventActivities: (eventId) => (
        fetch(`${APP_CONFIG.apiEndpoint}/v1/events/${eventId}/workshops?with_tags=1`, {
            crossDomain: true,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((r) => r.json())
    ),
    heartbeat: (_ticketToken) => (
        fetchWithTimeout(`${APP_CONFIG.apiEndpoint}/v1/live/heartbeat`, {
            timeout: 10 * 1000, // 10s
            crossDomain: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                t: `${_ticketToken}`,
            }),
        })
    ),
    logoutLive: (_ticketToken) => (
        fetch(`${APP_CONFIG.apiEndpoint}/v1/live/logout`, {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                t: `${_ticketToken}`,
            }),
        })
    ),
    logout: () => (
        fetch(`${APP_CONFIG.apiEndpoint}/v1/logout`, {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((r) => r.json())
    ),
    getTicket: (token) => (
        fetch(`${APP_CONFIG.apiEndpoint}/v1/me/tickets?token=${token}`, {
            crossDomain: true,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((r) => r.json()).then((data) => {
            if (!data.results || data.results.length === 0) {
                return null;
            }
            return data.results[0];
        })
    ),
    getMembers: (communityId, afterId, pageSize, query, token) => {
        const urlParams = { ticketToken: token };
        if (afterId) urlParams.afterId = afterId;
        if (pageSize) urlParams.pageSize = pageSize;
        if (query) urlParams.query = query;

        return fetch(`${APP_CONFIG.apiEndpoint}/v2/communities/${communityId}/members?${new URLSearchParams(urlParams)}`, {
            crossDomain: true,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((r) => r.json());
    },
    joinCommunity: (communityId, token) => (
        fetch(`${APP_CONFIG.apiEndpoint}/v2/communities/${communityId}/members`, {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                ticketToken: token,
            }),
        }).then((r) => r.json())

    ),
    leaveCommunity: (communityId, memberId, token) => (
        fetch(`${APP_CONFIG.apiEndpoint}/v2/communities/${communityId}/members/${memberId}`, {
            crossDomain: true,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                ticketToken: token,
            }),
        }).then((r) => r.json())
    ),
};
