import { applyMiddleware, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import userLoginReducer, {
    reducerKey as userLoginReducerKey,
    initialState as userLoginInitialState,
} from './userLogin';
import userLoginSaga from './userLogin/saga';
import eventReducer, {
    reducerKey as eventReducerKey,
    initialState as eventInitialState,
} from './event';
import eventSaga from './event/saga';
import apiTokensReducer, {
    reducerKey as apiTokensReducerKey,
    initialState as apiTokensInitialState,
} from './apiTokens';
import apiTokensSaga from './apiTokens/saga';

function* rootSaga() {
    // Init
    yield all([
        userLoginSaga(),
        eventSaga(),
        apiTokensSaga(),
    ]);
}

const getRootReducer = (history) => ({
    [userLoginReducerKey]: userLoginReducer,
    [eventReducerKey]: eventReducer,
    [apiTokensReducerKey]: apiTokensReducer,
    router: connectRouter(history),
});

const getInitialState = () => ({
    [userLoginReducerKey]: userLoginInitialState,
    [eventReducerKey]: eventInitialState,
    [apiTokensReducerKey]: apiTokensInitialState,
});

const bindMiddleware = (middlewares) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line global-require
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middlewares));
    }
    return applyMiddleware(...middlewares);
};

const createReducerManager = (initialReducers) => {
    let reducers = { ...initialReducers };
    let combinedReducer = combineReducers(reducers);
    return {
        reduce: (state, action) => combinedReducer(state, action),
        add: (key, reducer) => {
            if (!key || reducers[key]) return;
            reducers = {
                ...reducers,
                [key]: reducer,
            };
            combinedReducer = combineReducers(reducers);
        },
    };
};
const createSagaManager = (runSaga) => {
    const injectedSagas = new Map();
    return {
        add: (key, saga) => {
            if (injectedSagas.has(key)) return;
            const task = runSaga(saga);
            injectedSagas.set(key, task);
        },
    };
};

const makeStore = (history) => {
    const rootReducer = getRootReducer(history);
    const reducerManager = createReducerManager(rootReducer);
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        reducerManager.reduce,
        getInitialState(),
        bindMiddleware([
            sagaMiddleware,
            routerMiddleware(history),
        ]),
    );
    store.reducerManager = reducerManager;
    store.sagaManager = createSagaManager(sagaMiddleware.run);
    store.sagaManager.add('root', rootSaga);
    return store;
};

export default makeStore;
