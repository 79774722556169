import {
    all,
    put,
    takeLatest,
    select,
} from 'redux-saga/effects';
import { getLocation } from 'connected-react-router';

import {
    SUBMIT,
    getEmailAddress,
    getToken,
    setError,
    setIsLoading,
    setEmailAddress,
    setToken,
    setTicket,
} from './index';
import { getEventId } from '../event';

import YurplanAPI from '../../YurplanAPI';
import { loadTokenSuccess, setApiAccessToken } from '../apiTokens';
import { redirectToAppropriatePage } from '../apiTokens/saga';

function* submitLoginForm() {
    const [
        emailAddress,
        token,
        eventId,
    ] = yield all([
        select(getEmailAddress),
        select(getToken),
        select(getEventId),
    ]);
    yield put(setIsLoading(true));
    const loginResponse = yield YurplanAPI.login(emailAddress, token, eventId);
    if (loginResponse.status !== 200) {
        let error = 'UNKOWN_ERROR';
        switch (loginResponse.message) {
            case 'Invalid combinaison ticket token and email':
                error = 'INVALID_LOGIN';
                break;
            case 'Invalid session. Either the session has timed out, or you have been logged out':
            case 'Another session is active. Close other clients and try again':
                error = 'TICKET_CONFLICT';
                break;
            default:
                error = 'UNKOWN_ERROR';
        }
        yield all([
            put(setError(error)),
            put(setIsLoading(false)),
        ]);
        return;
    }
    yield all([
        put(setError(null)),
        put(setIsLoading(false)),
        put(setApiAccessToken(loginResponse.results.access_token)),
    ]);
    YurplanAPI.setTokens(loginResponse.results.access_token, token, emailAddress);
    YurplanAPI.saveTokens(loginResponse.results.expires_in);
    const ticketData = yield YurplanAPI.getTicket(token);
    yield put(setTicket(ticketData));
    yield put(loadTokenSuccess());
    yield redirectToAppropriatePage();
}

function* setInitialValue() {
    const { query } = yield select(getLocation);
    if (query.email) {
        yield put(setEmailAddress(query.email));
    }
    if (query.token) {
        yield put(setToken(query.token));
    }
}

function* rootSaga() {
    yield all([
        setInitialValue(),
        takeLatest([SUBMIT], submitLoginForm),
    ]);
}

export default rootSaga;
