import React from 'react';
import { useStore } from 'react-redux';

export default function withStore(Component) {
    return function WrappedComponent(props) {
        const store = useStore();
        return (
            <Component
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                store={store}
            />
        );
    };
}
