import React, { PureComponent, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from '../store/apiTokens';

import Routes from '../Routes';

import TopBar from '../components/TopBar';
import withStore from '../components/withStore';

export class EventRouter extends PureComponent {

    static propTypes = {
        UserLoginActions: PropTypes.shape({
            logout: PropTypes.func.isRequired,
        }).isRequired,
        store: PropTypes.shape({
            reducerManager: PropTypes.shape({
                add: PropTypes.func.isRequired,
            }).isRequired,
            sagaManager: PropTypes.shape({
                add: PropTypes.func.isRequired,
            }).isRequired,
        }).isRequired,
    }

    constructor(props) {
        super(props);
        this.addModule = this.addModule.bind(this);
    }

    addModule(module) {
        const { store } = this.props;
        store.reducerManager.add(
            module.reducerKey,
            module.reducer,
        );
        store.sagaManager.add(module.reducerKey, module.rootSaga);
        return { default: module.rootComponent };
    }

    renderRoutes() {
        return (
            <Suspense fallback={<div>Chargement...</div>}>
                <Switch>
                    <Route
                        path={Routes.scenes.raw}
                        component={lazy(() => (
                            import('../modules/stages/manifest')
                                .then(this.addModule)
                        ))}
                    />
                    <Route
                        path={Routes.community.raw}
                        component={lazy(() => (
                            import('../modules/community/manifest')
                                .then(this.addModule)
                        ))}
                    />
                    <Route
                        path={Routes.activities.raw}
                        component={lazy(() => (
                            import('../modules/activities/manifest')
                                .then(this.addModule)
                        ))}
                    />
                </Switch>
            </Suspense>
        );
    }

    render() {
        const { UserLoginActions } = this.props;
        return (
            <>
                <TopBar />

                <div className="c-grid c-grid--l">
                    <main className="c-grid__row">
                        {this.renderRoutes()}
                    </main>
                </div>

                <div className="c-grid c-grid--l mt-6 mb-4">
                    <div className="c-grid__row c-grid__row--no-col c-grid__row--space-between">
                        <a onClick={UserLoginActions.logout} className="c-link c-link--secondary u-font-size--3 mt--m-4">
                            Déconnexion
                        </a>
                    </div>
                </div>
            </>
        );
    }

}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    UserLoginActions: bindActionCreators({
        logout,
    }, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStore(EventRouter));
