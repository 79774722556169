const CONFIGURATION = {
    development: {
        endpoint: 'https://yurplan.local',
        apiEndpoint: 'https://api.yurplan.local',
        client_id: 'yurplan_live',
        client_secret: 'yurplan_live',
        cookie_domain: 'live.yurplan.local',
    },
    production: {
        endpoint: 'https://yurplan.com',
        apiEndpoint: 'https://api.yurplan.com',
        client_id: '6b3f25e38f6c5e887bc7',
        client_secret: '3j4jg4ghq0g04w88w4oskwg488ws4kk',
        cookie_domain: 'yurplan.live',
    },
};

export default {
    scope: 'stage_event',
    ...CONFIGURATION[process.env.NODE_ENV || 'development'],
};
