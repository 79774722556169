export const reducerKey = 'UserLogin';

export const initialState = {
    emailAddress: '',
    token: '',
    error: null,
    isLoading: false,
    ticket: null,
};

const ACTION_PREFIX = 'USER_LOGIN';

export const SET_EMAIL_ADDRESS = `${ACTION_PREFIX}/SET_EMAIL_ADDRESS`;
export const SET_TOKEN = `${ACTION_PREFIX}/SET_TOKEN`;
export const SUBMIT = `${ACTION_PREFIX}/SUBMIT`;
export const SET_IS_LOADING = `${ACTION_PREFIX}/IS_LOADING`;
export const SET_ERROR = `${ACTION_PREFIX}/ERROR`;
export const SET_TICKET = `${ACTION_PREFIX}/SET_TICKET`;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_EMAIL_ADDRESS:
            return {
                ...state,
                emailAddress: action.payload.emailAddress.trim(),
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload.token.trim(),
            };
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload.error,
            };
        case SET_TICKET:
            return {
                ...state,
                ticket: action.payload.ticket,
            };
        default:
            return state;
    }
}

export const setEmailAddress = (emailAddress) => ({
    type: SET_EMAIL_ADDRESS,
    payload: {
        emailAddress,
    },
});

export const setToken = (token) => ({
    type: SET_TOKEN,
    payload: {
        token,
    },
});

export const setError = (error) => ({
    type: SET_ERROR,
    payload: {
        error,
    },
});
export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: {
        isLoading,
    },
});
export const setTicket = (ticket) => ({
    type: SET_TICKET,
    payload: {
        ticket,
    },
});
export const submit = () => ({ type: SUBMIT });

export const getEmailAddress = (state) => state[reducerKey].emailAddress;
export const getToken = (state) => state[reducerKey].token;
export const getError = (state) => state[reducerKey].error;
export const isLoading = (state) => state[reducerKey].isLoading;

export const ERROR_MESSAGES = {
    INVALID_LOGIN: 'Vos identifiants semblent invalides',
    UNKNOWN: 'Une erreur inconnue est survenue',
    TICKET_CONFLICT: 'Votre billet est en cours d\'utilisation. Déconnectez-vous de vos autres appareils pour accéder à l\'évènement',
};

export const getTicket = (state) => state[reducerKey].ticket;
export const ticketHasCommunityEnabled = (state) => {
    const me = getTicket(state);
    if (!me) return false;
    return !!me.community_member_id;
};
