import React from 'react';

import notFoundImage from '../assets/yurplan_live_404.svg';

const Error404 = () => (
    <div className="c-grid c-grid--s mt--s-0">
        <div
            className="c-grid__row c-grid__row--no-col c-grid__row--align-vcenter c-grid__row--align-hcenter pb-6"
            style={{
                height: '100%',
                minHeight: 'calc(100vh - 50px)',
            }}
        >
            <div className="u-col--m-10 u-col-12">
                <img className="u-width--full mb-4" src={notFoundImage} alt="illustration page non trouvée" />
                <div className="u-font-size--8 u-font-weight--semi-bold u-color__grey--darker mb-3">
                    Oups.
                </div>
                <div className="u-font-size--4 u-font-weight--semi-bold u-color__grey--darker mb-3">
                    Il semblerait que la page que vous cherchez se soit envolée.
                </div>
                <div className="u-font-size--3 u-color__grey--darker my-4">
                    La page a été peut être supprimée, le lien vers cette page
                    est erroné, ou l’url n’est pas correcte.
                </div>
                <a href="/" className="c-btn c-btn--tertiary">
                    Revenir à la page d'accueil →
                </a>
            </div>
        </div>
    </div>
);

export default Error404;
