import React from 'react';

import UserLoginForm from '../components/UserLoginForm';
import EventPreview from '../components/EventPreview';

const LoginPage = () => (
    <div className="c-grid c-grid--m mt-8 mt--s-0">
        <div className="c-grid__row">
            <EventPreview />
            <UserLoginForm />
        </div>
    </div>
);

export default LoginPage;
