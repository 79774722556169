import React from 'react';

const HelpMessage = () => (
    <div className="u-font-size--3 u-color__grey--darker">
        Vous n’arrivez pas à accéder à l’évènement ?
        <br />
        <a className="c-link c-link--secondary" href="https://aide.yurplan.com">
            Veuillez consulter notre FAQ
        </a>
    </div>
);

export default HelpMessage;
